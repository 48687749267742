/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        const observer = lozad('.lazyload', {
            rootMargin: '10px 0px', // syntax similar to that of CSS Margin
            threshold: 0.1, // ratio of element convergence
            enableAutoReload: true // it will reload the new image when validating attributes changes
        });
        observer.observe();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        //Accessibilité :  On supprime le glow sur les éléments sauf si l'utilisateur utilise la touche tab.
        function handleFirstTab(e) {
          if (e.keyCode === 9) { // the "I am a keyboard user" key
            document.body.classList.add('user-is-tabbing');
            window.removeEventListener('keydown', handleFirstTab);
          }
        }
        window.addEventListener('keydown', handleFirstTab);

        $('#gotop').on('click', function (event) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: 0
          }, 1000);
        });

        // Manage go to top appear on scroll up
        $(window).load(function () {

          // Hide Header on on scroll down
          var didScroll;
          var lastScrollTop = 0;
          var delta = 100;
          var totop = $('.go-to-top-wrapper');
          var st = 5;

          function hasScrolled(tst) {
            var st = tst;
            if (Math.abs(lastScrollTop - st) <= delta){
              return;
            }

            if (st > lastScrollTop || st < 300 ) {
              // Scroll Down
              totop.removeClass('visible');

            } else {
              // Scroll Up
              if (st + $(window).height() < $(document).height() ) {
                totop.addClass('visible');
              }
            }
            lastScrollTop = st;
          }

          $(window).scroll(function () {
            didScroll = true;
            st = $(this).scrollTop();
          });

          setInterval(function () {
            if (didScroll) {
              var tst = $(this).scrollTop();
              hasScrolled(tst);
              didScroll = false;
            }
          }, 250);

        });

        /** STAGES / ECOLE DATES */
        $('body').on('click', '#more-dates', function (e) {
            e.preventDefault();
            var nbToMoove = $(this).data('nbload');
            var elements = $('.list-stage-hidden li:lt(' + nbToMoove + ')');
            $('.list-stage').append( elements );

            setTimeout(function () {
              $('.list-stage .animateLi').css("opacity", "1");
            }, 100);

            if ( $('.list-stage-hidden li').length === 0 ) {
              $('#more-dates').remove();
            }
        });

        $('body').on('click', '#more-news', function (e) {
           event.preventDefault();
          //  $('.notif-info').hide();
           var nonce = $(this).data('nonce'),
               page = $(this).data('page'),
               post_per_page = $(this).data('nb'),
               $this = $(this),
               textButton = $(this).text();

           $this.text('Chargement');

           $.ajax({
               url: psg.ajaxurl,
               type: 'POST',
               data: {
                 'action': 'news',
                 'paged': page,
                 'posts_per_page': post_per_page,
                 'nonce': nonce,
               },
               success: function (data) {
                 console.log(data);
                 if (data.result === 'success') {
                   $('.list-news-wrapper .row').append(data.html);
                   $this.data().page++;
                   if(data.continue === false) {
                      $('#more-news').remove();
                   }
                 }
               }
             })
             .done(function () {
               $this.text("Voir plus d'actualités");
              //  $('#form-notif').trigger("reset");
              //  setTimeout(function () {
              //    $("#popup-notitifications").modal('hide');
              //    $('.notif-info').hide('fast');
              //  }, 2500);
             });

        });

        /** Sticky Header **/
        var header = document.getElementById("psg-academy-header");
        var sticky = header.offsetTop + 300;

        function stickPsgHeader() {
          if (window.pageYOffset >= sticky) {
            header.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
          }
        }

        window.onscroll = function () {
          stickPsgHeader();
        };

        var i18n = {
          previousMonth: 'Mois précédent',
          nextMonth: 'Mois prochain',
          months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', "Octobre", "Novembre", "Décembre"],
          weekdays: ['dimanche', " lundi ", " mardi ", " mercredi ", " jeudi ", " vendredi ", " samedi "],
          weekdaysShort: ['Dim', 'lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
        };

        var picker = new Pikaday({
          field: document.getElementById('birthdate'),
          format: 'DD/MM/YYYY',
          firstDay: 1,
          i18n: i18n,
          yearRange: 20,
          maxDate: new Date(),
          onSelect: function (date) {
            var options = {
              weekday: 'numeric',
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            };
            document.getElementById('birthdate').value = date.toLocaleDateString('fr-FR');
          }
        });

        var picker2 = new Pikaday({
          field: document.getElementById('birthdate_2'),
          format: 'DD/MM/YYYY',
          firstDay: 1,
          i18n: i18n,
          yearRange: 20,
          maxDate: new Date(),
          onSelect: function (date) {
            var options = {
              weekday: 'numeric',
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            };
            document.getElementById('birthdate_2').value = date.toLocaleDateString('fr-FR');
          }
        });

        var picker3 = new Pikaday({
          field: document.getElementById('birthdate_3'),
          format: 'DD/MM/YYYY',
          firstDay: 1,
          i18n: i18n,
          yearRange: 20,
          maxDate: new Date(),
          onSelect: function (date) {
            var options = {
              weekday: 'numeric',
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            };
            document.getElementById('birthdate_3').value = date.toLocaleDateString('fr-FR');
          }
        });

        $(document).on('click', 'a[href*="#"]:not([href="#"])', function (event) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - 100
          }, 500);
        });

        var is_touch_device = ("ontouchstart" in window) || window.DocumentTouch && document instanceof DocumentTouch;
        // is_touch_device ? alert('ok') : alert('ko');
        var delayHide = is_touch_device ? 10 : 500;


        $('[data-toggle="popover"]').popover({
          placement: 'top',
          trigger: 'focus',
          html: true,
          // delay: {
          //   show: "0",
          //   hide: "500"
          // },
        }).on("mouseenter", function () {
          var _this = this;
          $(this).popover("show");
          $(".popover").on("mouseleave", function () {
            $(_this).popover('hide');
          });
        }).on("mouseleave", function () {
          var _this = this;
          setTimeout(function () {
            if (!$(".popover:hover").length) {
              $(_this).popover("hide");
            }
          }, delayHide);

        });

        /** POPHOVER REMOVE ON CLICK OUTSIDE */
        $(document).on('touch', function (e) {
          $('[data-toggle="popover"],[data-original-title]').each(function () {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
              (($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false; // fix for BS 3.3.6
            }

          });
        });



        $(document).on('click', 'button.navbar-toggler', function() {
          $('body').toggleClass('menu-open');
        });

        var nb_click = 0;
        $(document).on("click", '.other-child', function(e) {
          e.preventDefault();

          if(nb_click === 0 ) {
            $('.child-two').addClass("show");
          }

          if(nb_click === 1 ) {
            $('.child-three').addClass("show");
            $('.more-childs-btn').show();
            $(this).hide();
          }

          nb_click++;

        });

        /**
         * Comportement mobile:
         * Au premier touch, on ouvre, au second on va sur le lien.
         */
        // $(document).on('touchstart', '#psg-academy-header .navbar-nav [data-hover=dropdown]', function (e) {
        //   e.preventDefault();
        //   var attr = $(this).attr('aria-expanded');
        //   if(attr === 'true') {
        //       window.location.href = $(this).attr('href');
        //   } else {
        //     $(this).dropdown('toggle');
        //   }
        // });

        // $(document).on('touchstart', '#psg-academy-header .navbar-nav .dropdown', function (e) {
        //   e.preventDefault();
        //   $(this).dropdown('toggle');
        //    var attr = $(this).attr('aria-expanded');
        //    if(attr === 'true') {
        //        window.location.href = $(this).attr('href');
        //    } else {
        //      $(this).dropdown('toggle');
        //    }
        // });


        $(document).on('click', '#psg-academy-header .navbar-nav .dropdown-toggle', function (e) {
          if (window.matchMedia("(min-width: 1024px)").matches) {
            window.location.href = $(this).attr('href');
          }
        });


        /** MENU MORE **/
        var menuItem = $('#psg-academy-header .navbar-nav > li:not(.menu-more)').length;
        var menuMore = $('#psg-academy-header .navbar-nav > li:last-child');

        function manageMoreItem() {

          if (menuItem > 6 ) {
            if (window.matchMedia("(min-width: 1024px)").matches ) {
              var items = $('#psg-academy-header .navbar-nav > li:nth-child(1n + 6):not(:last-child)');
              items.appendTo('#more-item-menu > ul');
              $('#more-item-menu').show();
              $('#more-item-menu ul li').addClass('link-hover');
            } else {
            }
          }
        }
        /** MENU MOBILE on déplace le niveau clicable dans le sous-menu  */
        function manageMenuMobile() {
          // $('.menu-item-has-children').addNode('<li>TEST</li>');
          if (window.matchMedia("(max-width: 1024px)").matches) {
            var text, href, added;
            $('#menu-menu-principal > li.dropdown').each(function() {
              text = $(this).find("a > span").text();
              href = $(this).find("a").attr('href');
              added = $(this).find('.dropdown-menu .menu-added').length;
              if (href !== '#' && !added ) {
                $(this).find('.dropdown-menu').prepend('<ul class="menu-added"><li><a class="" href="'+href+'">'+text+'</a></li></ul>');
              }
            });
            // truncateTitle();
          } else {
            $('.menu-added').remove();
          }
        }

        $(window).on('resize load', function () {
          manageMoreItem();
          manageMenuMobile();
        });


        //Manage hover pour le bouton share on smartphone
        $(".block-social-share").on("mouseenter mouseleave touchstart", function (e) {
          if (e.type === 'touchstart') {
            $(this).off('mouseenter mouseleave');
          }
          $(this).toggleClass("hover");
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $('.psg-slider').slick({
            slidesToShow: 1,
            arrows: false,
            dots: false,
            infinite: false,
            asNavFor: '.psg-slider-nav',
            autoplay: true,
            autoplaySpeed: 3000

        });

        $('.psg-slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            asNavFor: '.psg-slider',
            dots: false,
            infinite: false,
            // centerMode: true,
            focusOnSelect: true,
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    "ecoles" : {
      init: function() {

      },
      finalize: function () {
        var annivResults = document.querySelector(".section--anniv--res");
        
        function handleOrientationChange(cardInfoInitialParent, cardInfo) {
          if (window.innerWidth >= 1024) {
            cardInfoInitialParent.append(cardInfo);
          }
        }

        if (annivResults) {
          if(!slotsInformations) {
              console.error("Missing slots Data =(")
              return;
          }
  
          const Checkblocks = annivResults.querySelectorAll(".CheckBlock");
          const type_pictureUrl = document.getElementById("type_pictureUrl");
          const type_name = document.getElementById("type_name");
          const centerName = document.getElementById("centerName");
          const type_terrain = document.getElementById("type_terrain");
          const type_description = document.getElementById("type_description");
          const amount = document.getElementById("amount");
  
          const cardInfoInitialParent = document.getElementById("cardInfo-container");
          const cardInfo = document.getElementById("cardInfo");
          const cardInfoArrow = cardInfo.querySelector(".cardInfo_arrow")
          const emptyMessage = document.getElementById("empty-message");
  
          const mediaQuery = window.matchMedia("(max-width: 1024px"); // scss $medium
          Checkblocks.forEach(
              checkblock => {
                  checkblock.addEventListener(
                      "click",
                      (e) => {
                          const slotId = checkblock.getAttribute("for");
                          const slotInfo = slotsInformations[slotId];
                          const slotInput = document.getElementById(slotId);
  
                          if (slotInput.checked) {
                              e.preventDefault();
                              e.stopPropagation();
                              slotInput.checked = false;
                              if (!mediaQuery.matches) {
                                  emptyMessage.classList.remove("hide");
                              } else {
                                  cardInfo.classList.add("hide");
                              }
                          } else {
                              emptyMessage.classList.add("hide");
                              cardInfo.classList.remove("hide");
                              
                              type_pictureUrl.src = slotInfo.type.pictureUrl;
                              type_name.innerText = slotInfo.type.name;
                              if(slotInfo.type.type_terrain){
  
                                  if (slotInfo.type.type_terrain.includes('Filmé')) {
                                      var label = slotInfo.type.type_terrain.split(' ')[1];
                                    if (label == "indoor") {
                                        label = "intérieur";
                                    }
                                      type_terrain.innerText = label[0].toUpperCase() + label.substring(1);
                                  } else {
                                      type_terrain.innerText = slotInfo.type.type_terrain;
                                  }
                              }
                              centerName.innerText = slotInfo.centerName;
                              type_description.innerHTML = slotInfo.type.description;
                              amount.innerText = slotInfo.amount;
  
                              if (window.innerWidth < 1024) {
                                  cardInfoInitialParent.append(cardInfo);
                                  const parent = checkblock.parentNode;
                                  const parentParent = parent.parentNode;
  
                                  const parentIndex = Array.prototype.indexOf.call(parentParent.children, parent)
  
                                  const isLeftSide = parentIndex % 2 == 0;
                                  if (isLeftSide) {
                                      cardInfoArrow.classList.remove("right");
                                      parentParent.children[parentIndex + 1].after(cardInfo);
                                  } else {
                                      cardInfoArrow.classList.add("right");
                                      parent.after(cardInfo);
                                  }
                              }
                          }
                      }
                  )
              }
          );
  
          
          function handleOrientationChange(mql) {
              if(!mql.matches) {
                  cardInfo.classList.remove("hide");
                  cardInfoInitialParent.append(cardInfo);
              } else {
                  cardInfo.classList.add("hide");
              }
          }
          handleOrientationChange(mediaQuery);
          mediaQuery.addEventListener(
              'change',
              handleOrientationChange
          );
      }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
